import React from "react"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import Player from "@app-components/ui/player/"
import Grid from "@app-components/ui/grid/"
import AnalyticsImage from "@page-components/products/files/features/online-screeners/analytics/"
import WatermarkImage from "@page-components/products/files/features/online-screeners/watermarks/"
import ScreenerRoomImage from "@page-components/products/files/features/online-screeners/rooms/"
import HEADERS from "@data/headers/"

const OnlineScreeners = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="features/online-screeners">
    <Header
      title="Secure Online Screeners"
      blue
      text="Share your content confidently with buyers and reviewers and gain insight into who watched what."
      image={<Player />}
      secondaryNavigation={HEADERS.files}
    />
    <Feature
      title="Detailed Analytics"
      text="Monitor the overall performance of your online screeners using aggregated charts, or drill-down to analyze an individual reviewer's activity using heat maps."
      icon="chart-white"
      image={<AnalyticsImage />}
    />
    <Feature
      title="Personalized watermarks secured with PiracyDetector™"
      text={`Send your screeners using PiracyDetector™, an "active" watermarking technology that monitors the integrity of the mark and prevents unauthorized removal.`}
      reverse
      icon="watermark-white"
      image={<WatermarkImage />}
    />
    <Grid
      title="Additional CineSend Screener Benefits"
      columnWidth={4}
      items={[
        {
          title: "Studio-Grade DRM",
          text:
            "CineSend screeners are multi-DRM enabled, preventing users across all platforms from downloading and redistributing your content.",
          _id: 1,
          icon: "shield-white"
        },
        {
          title: "Custom Expiration Rules",
          text: `Specify an expiry date when you send a screener. If your reviewer needs more time, they can request an extension for you to approve or deny. You can also revoke links at any time.`,
          _id: 2,
          icon: "date-white"
        },
        {
          title: "Password Protection",
          text:
            "For added security, enable a password reviewers must enter before they can watch your screeners. You can even set up a unique password for each reviewer.",
          _id: 3,
          icon: "lock-white"
        },
        {
          title: "Export Reports",
          text:
            "Export printable PDF reports that show the viewing activity of each reviewer. If you want to crunch numbers, you can also export a CSV with the same data.",
          _id: 4,
          icon: "export-white"
        },
        {
          title: "Email or URL Sharing",
          text:
            "Send screeners to a reviewer directly by email, or create URLs that you can paste into festival submission platforms or deliver to third parties.",
          _id: 5,
          icon: "url-white"
        },
        {
          title: "HD Playback",
          text:
            "With adaptive bitrate, CineSend delivers the best possible streaming quality to your reviewers without the risk of excessive buffering, on any kind of device.",
          _id: 6,
          icon: "hd-white"
        }
      ]}
    />
    <Feature
      background="gray-right-rounded"
      title="Custom Screener Rooms"
      text="Group multiple projects together into a Screener Room to share with reviewers as a single link. Set a custom synopsis, list of key actors, or any other information you want for each project. You can even include trailers, or press clippings alongside the feature presentation."
      icon="movie-white"
      image={<ScreenerRoomImage />}
    />
  </Layout>
)

export default OnlineScreeners
